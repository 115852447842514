import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getTicketTypesByEventId } from "ticketino-api-client";
import { Calendar } from "react-calendar";
import parse from "html-react-parser";

import Header from "./components/Header";
import Footer from "./components/Footer";
import "../css/style.css";
import "../css/calendar.css";
import Loading from "./components/Loading";
import voucherimage from "../images/voucher.jpg";
import masterclassimage from "../images/masterclass.jpg";
import recommendedEventimage from "../images/recommendedEvent.jpg";

const Home = () => {
    const [date, setDate] = useState(new Date());
    const [posId, setPosId] = useState();
    const [eventId, setEventId] = useState();
    const [events, setEvents] = useState([]);
    const [orderId, setOrderId] = useState();
    const [eventGroupId, setEventGroupId] = useState();
    const [eventDates, setEventDates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        loadToken();

        // clear the addedPromocodes in the session
        let array = [];
        sessionStorage.setItem("AddedPromotions", JSON.stringify(array));
    }, []); //only on first page load

    useEffect(() => {
        requestResources();

        // so it wont get called on first load
        if (loaded) {
            startOrder(posId, eventId);
        }
    }, [language]); //everytime language is changed

    useEffect(() => {
        if (orderId) {
            loadEvent(eventGroupId, orderId)
        }
    }, [orderId]); //gets called when an order is started

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {
            // setting the posID and eventID
            setPosId(res.data.posId);
            setEventId(res.data.eventId);
            startOrder(res.data.posId, res.data.eventId);
            setEventGroupId(res.data.eventGroupId);

            sessionStorage.setItem("masterEventId", res.data.masterEventId);
            sessionStorage.setItem("recommendedWeinschiffeEventId", res.data.recommendedWeinschiffeEventId);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);
                setOrderId(res.data.id);
                setLoaded(true);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadEvent = async (eventGroupId, orderId) => {
        try {
            setLoading(true);

            let updatedEvents = await getEventsByEventGroupId(eventGroupId);

            let datesArray = [];

            updatedEvents.map(event => {
                datesArray.push(event.start?.split('T')[0]);
            })

            updatedEvents = updatedEvents.filter(ue => (ue.id != 193133));

            updatedEvents = await Promise.all(updatedEvents.map(async (event) => {
                let ticketTypes = await getTicketTypesByEventId(event.id);
                ticketTypes.sort((a, b) => a.sortOrder - b.sortOrder);

                const ticketTypePrice = ticketTypes[1]?.price;
                const ticketTypePrice2 = ticketTypes[2]?.price;

                let classNameColor = "";

                if (ticketTypePrice == 33 && ticketTypePrice2 == 28) {
                    classNameColor = "lightgrey"
                } else if (ticketTypePrice == 38 && ticketTypePrice2 == 28) {
                    classNameColor = "blue"
                } else if (ticketTypePrice == 28) {
                    classNameColor = "darkgrey"
                } else if (ticketTypePrice == 30) {
                    classNameColor = "lightgrey"
                } else if (ticketTypePrice == 33) {
                    classNameColor = "lightgreyer"
                } else if (ticketTypePrice == 38) {
                    classNameColor = "darkgrey"
                }

                return { ...event, color: classNameColor }
            }))

            setEventDates(datesArray);
            setEvents(updatedEvents);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const getEventsByEventGroupId = async (eventGroupId) => {
        try {
            const res = await axios.get(`${baseUrl}/EventGroup/Events?eventGroupId=${eventGroupId}&languageCode=${language != "de" || language != "fr" || language != "it" || language != "en" ? "de" : language}`);
            return res.data?.events;
        } catch (error) {
            console.error(error);
        }
    }

    const onSubmit = async () => {
        navigate("/tickets");
    };

    const tileClassName = ({ date }) => {
        const dateString = new Date(date);
        dateString.setDate(dateString.getDate() + 1);
        const formattedDate = dateString.toISOString().split('T')[0];

        if (eventDates.includes(formattedDate)) {
            let selectedDate = new Date(date);
            selectedDate.setDate(selectedDate.getDate() + 1);
            const formattedDate = selectedDate.toISOString().split('T')[0];
            const event = events.find(e => e.start?.split('T')[0] == formattedDate);

            return `bold-date ${event?.color} ${language}${event?.color}`;
        } else {
            return 'disabled-date';
        }
    };

    const onClickDay = (date) => {
        let selectedDate = new Date(date);
        selectedDate.setDate(selectedDate.getDate() + 1);
        const formattedDate = selectedDate.toISOString().split('T')[0];

        const event = events.find(e => e.start?.split('T')[0] == formattedDate);

        if (event?.id > 0) {
            navigate(`/${language}/event/${event.id}`);
        }
    }

    return (
        <div className="container wrapper">
            <Header resources={resources} showLanguage={true} recommendedEventsHeader={false} />
            <div className="mt-4">
                <div className="mb-4 times-container">
                    <div className="row times">
                        <p className="text fs-6 fw-bold mb-2">{resources?.OpeningTimesTitle}</p>
                        {parse(resources?.OpeningTimes1 ?? "")}
                        {parse(resources?.OpeningTimes2 ?? "")}
                        {parse(resources?.OpeningTimes3 ?? "")}
                        {parse(resources?.OpeningTimes4 ?? "")}
                    </div>
                </div>
                {!loading ? <div>
                    <p className="fs-6 text-container mb-3">{resources?.SelectDate}</p>
                    <div className="calendar-container mb-2">
                        <Calendar
                            activeStartDate={new Date(2024, 9, 1)}
                            view="month"
                            value={date}
                            onChange={setDate}
                            tileClassName={tileClassName}
                            onClickDay={onClickDay}
                            locale={language}
                            showNeighboringMonth={false}
                        />
                        <Calendar
                            activeStartDate={new Date(2024, 10, 1)}
                            view="month"
                            value={date}
                            onChange={setDate}
                            tileClassName={tileClassName}
                            onClickDay={onClickDay}
                            locale={language}
                            showNeighboringMonth={false}
                        />
                    </div>
                    <div className="row">
                        <div className="col">
                            <p className="text-start mb-2" style={{ fontSize: "small", color: "#3E6C85" }}>{parse(resources?.Note1 ?? "")}</p>
                            <p className="text-start mb-4" style={{ fontSize: "small", color: "#3E6C85" }}>{parse(resources?.Note2 ?? "")}</p>
                        </div>
                        <div className="col">
                            <p className="text-end mb-4" style={{ fontSize: "small", color: "#3E6C85" }}>{resources?.Note}</p>
                        </div>
                    </div>
                </div> : <Loading bgColor="#fff" color="#3E6C85" />}
                <div className="row text-start">
                    <div className="col-md-5 col-12 text-center">
                        <img className="img-fluid" src={voucherimage}></img>
                    </div>
                    <div className="col-md-7 col-12 description-div">
                        <p>{parse(resources.VoucherDesc ?? "")}</p>
                        <div className="text-end description-div-button">
                            <button className="button text-end" onClick={() => navigate(`/${language}/event/193133`)}>{resources?.BookVoucher}</button>
                        </div>
                    </div>
                </div>
                <div className="row text-start mt-5">
                    <div className="col-md-5 col-12 text-center">
                        <img className="img-fluid" src={masterclassimage}></img>
                    </div>
                    <div className="col-md-7 col-12 description-div">
                        <div>
                            <label className="fw-bold">
                                {resources?.ComingSoonMasterClasses}
                            </label>
                        </div>
                        <div><p>{parse(resources.MasterclassDesc ?? "")}</p></div>
                        <div className="text-end description-div-button">
                            <div>
                                <a className="button masterclass-btn text-center" href={resources?.BookMasterClassEventUrl}>{resources?.BookTickets}</a>
                            </div>
                            <div className="mt-1">
                                <a className="button masterclass-btn text-center" href="https://expovina.ch/de-ch/weinschiff/masterclasses">{resources?.LearnMore}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-start mt-5">
                    <div className="col-md-5 col-12 text-center">
                        <img className="img-fluid" src={recommendedEventimage}></img>
                    </div>
                    <div className="col-md-7 col-12 description-div">
                        <div>
                            <label className="fw-bold">
                                {resources?.ComingSoonEvents}
                            </label>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: resources?.EventsDesc }}></div>
                        <div className="text-end description-div-button">
                            <div>
                                <a className="button masterclass-btn text-center" href={resources?.BookWeinschiffeEventUrl}>{resources?.BookTickets}</a>
                            </div>
                            <div className="mt-1">
                                <a className="button masterclass-btn text-center" href=" https://expovina.ch/de-ch/weinschiff/klubschiff">{resources?.LearnMore}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-start mt-4 mb-4">
                    <a className="button text-start" href="https://expovina.ch/de-ch/primavera">
                        {resources?.Back}
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Home